export function Logomark(props) {
  return (
    <svg
      viewBox="37.4 13.88 425.53 461.48"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      {...props}
    >
      <path
        d="m108.27 227.25-4.301-5.155 27.688-13.349 1.536-10.959 14.339-11.915 19.494-16.216 22.43-7.989 14.715-16.115 16.626-13.826 9.252 2.97L248.69 93.01l20.621-17.172 13.314-15.466 14.749-7.92 35.813-38.579-33.696 27.073-19.494 10.379-9.082 12.393-10.993 4.302-25.776 21.474s-16.251 27.107-17.002 29.668c-.751 2.56-8.228 2.97-8.228 2.97l-25.025 21.782 2.185-8.433s-24.649 25.161-28.848 24.786c-4.2-.376-17.958 22.703-17.958 22.703l-38.681 29.293-8.228 14.612-8.023.853-46.909 49.709 25.196-20.997 45.645-29.19z"
        fill="#de3a3a"
        data-layer-id={6562545520390}
      />
      <path
        d="m220.76 239.85 9.252 2.97 18.641-41.685 20.621-17.173 13.314-15.465 14.749-7.921 35.813-38.544-33.696 27.073-19.494 10.379-9.082 12.393-10.993 4.301-25.776 21.475s-16.251 27.107-17.002 29.668c-.751 2.56-8.228 2.97-8.228 2.97l-25.025 21.781 2.185-8.432s-24.649 25.161-28.848 24.786c-4.2-.376-17.958 22.703-17.958 22.703l-38.681 29.293-8.228 14.612-8.023.853-46.909 49.709 25.196-20.997 45.543-29.19-4.302-5.155 27.688-13.349 1.536-10.959 14.339-11.915 19.494-16.217 22.431-7.988 14.714-16.115 16.729-13.861z"
        fill="#de3a3a"
        data-layer-id={1773385220416}
      />
      <path
        d="m252.92 315.64-3.755-4.541 24.308-11.71 1.331-9.627 12.564-10.482 17.104-14.236 19.699-7.033 12.905-14.168 14.578-12.154 8.126 2.594 16.353-36.598 18.094-15.056 11.711-13.554 12.939-6.93 31.443-33.833-29.6 23.761-17.104 9.116-7.955 10.891-9.627 3.755-22.635 18.845s-14.271 23.796-14.92 26.05c-.683 2.253-7.203 2.594-7.203 2.594l-21.953 19.119 1.912-7.409s-21.611 22.089-25.298 21.748c-3.687-.342-15.773 19.938-15.773 19.938l-33.935 25.708-7.204 12.802-7.033.751-41.173 43.632 22.123-18.402 39.978-25.571z"
        fill="#de3a3a"
        data-layer-id={463586520438}
      />
      <path
        d="m433.36 267.78-17.104 9.116-7.955 10.89-9.627 3.756-22.635 18.845s-14.271 23.796-14.92 26.049c-.683 2.254-7.203 2.595-7.203 2.595l-21.953 19.119 1.912-7.409s-21.611 22.089-25.298 21.748c-3.687-.342-15.773 19.938-15.773 19.938l-33.935 25.707-7.204 12.803-7.033.751-41.207 43.666 22.123-18.402 39.978-25.605-3.755-4.541 24.308-11.71 1.331-9.628 12.564-10.481 17.104-14.236 19.699-7.033 12.905-14.169 14.578-12.154 8.126 2.595 16.353-36.598 18.094-15.056 11.71-13.554 12.94-6.931 31.443-33.833-29.566 23.762z"
        fill="#de3a3a"
        data-layer-id={7536383420460}
      />
    </svg>
  )
}

export function Logo(props) {
  return (
    <div className="flex h-full items-center space-x-2">
      <svg viewBox="0 0 45 40" aria-hidden="true" {...props}>
        <Logomark width="40" height="40" />
      </svg>
      <span className="font-medium text-gray-200">Dotabod</span>
    </div>
  )
}

export function DarkLogo(props) {
  return (
    <div className="flex h-full items-center space-x-2">
      <svg viewBox="0 0 45 40" aria-hidden="true" {...props}>
        <Logomark width="40" height="40" />
      </svg>
      <span className="text-2xl font-medium text-gray-200">Dotabod</span>
    </div>
  )
}
